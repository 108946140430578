import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import notFoundImg from 'src/images/404/notfound.svg'

const NotFoundPage = () => {
    return (
    <Layout>
        <Seo title="404: Not found" />
        <div style={{'minHeight': 'calc(100vh - 60px - 100px)'}}>
            <h1 className="
                w-7/12 mx-auto pt-24 mb-28
                md:w-1/3 md:pt-40 md:mb-20
                xl:w-1/4
            "><img src={notFoundImg} alt="404 Not Found" width="321" height="158" /></h1>
            <p className="
                text-center text-13px tracking-06em leading-25px font-medium mb-64
                md:text-16px md:mb-32
            ">申し訳ございません。<br />指定されたページは存在しません。</p>
        </div>
  </Layout>);
};

export default NotFoundPage
